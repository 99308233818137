form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    margin-bottom: 2rem;
  }

  input {
    font-size: calc(10px + 1vmin);
    background: transparent;
    border: 1px solid var(--primary-color);
    padding: 1rem;
    width: calc(100% - 2rem);
    outline: none;
    color: rgba(255,255,255,.5);
  }
}
