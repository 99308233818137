.articles {
  display: grid;
  gap: 1rem;

  article {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 1rem;
    align-items: center;
    a {
      align-self: flex-end;
    }
  }
}
