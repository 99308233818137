.template {
  textarea {
    width: 100%;
    min-height: 15rem;
    resize: vertical;
    font-size: 1rem;
  }

  dl {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: 1rem;
    row-gap: .5rem;
    font-size: .8rem;
  }
}
