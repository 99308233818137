body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #3498db;
  --success-color: #4bac3f;
  --alternate-background-color: rgba(255, 255, 255, .01);
  --gray: #444957;
  --gray-light: #71798f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

hr {
  height: 1px;
  border: none;
  margin: 2rem 0;
  background-color: var(--primary-color);
}

.Toastify {
  font-size: 1rem;
}

.button {
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid whitesmoke;
  border-radius: 1rem;
  padding: .5rem 2rem;
  color: whitesmoke;
  cursor: pointer;
  outline: none;
  white-space: nowrap;

  :first-child {
    margin-right: .5rem;
  }

  &:hover {
    background: var(--primary-color);
  }

  &--inline {
    display: inline-flex;
    padding: .25rem 1rem;
    font-size: .8rem;
  }

  &--plain {
    border: none;
    font-size: 2.5vmin;
    padding: 0;

    &:hover {
      color: var(--primary-color);
      background: none;
    }
  }

  &--warning {
    &:hover {
      background: tomato;
    }
  }
}

.bmc-btn-container {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}

.text-center {
  text-align: center;
}

.loading {
  animation: loading 1s infinite;
  margin-right: 1rem;
}

.has-loader {
  display: flex;
  align-items: center;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
